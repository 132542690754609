import { Icons } from "components/molecules/icon/icon.interface";
import { UserType } from "lib/constants/user-type";

export type HeaderProps = {
    isActive: boolean;
    isMobile?: boolean;
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
    onBack?: () => void;
    pageTitle?: string;
    showBackButton?: boolean;
};

export type StyledHeaderBlockProps = {
    isMobile?: boolean;
};

export const testIds = {
    profileMenuButton: "profile-menu-button",
    myProfileButton: "my-profile-button",
    logOutButton: "log-out-button",
    desktopBackButton: "desktop-back-button",
};

export const MOBILE_LOGO_ICON_NAME_BY_USER_TYPE = {
    [UserType.AGGREGATOR_USER]: Icons.aggLogoSvg,
    [UserType.B2B_USER]: Icons.aggLogoSvg,
    [UserType.PROVIDER]: Icons.aggLogoSvg,
};
