import React from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

import { Button } from "components/atoms/button/button.component";
import { ButtonSize } from "components/atoms/button/button.interface";
import { Menu } from "components/atoms/menu/menu.component";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { Text } from "components/atoms/text/text.component";
import { useLogout } from "lib/hooks/use-logout.hook";
import { PageInfo } from "../page-info/page-info.component";
import { BurgerMenuButton } from "./components/burger-menu-button/burger-menu-button.component";
import { paths } from "lib/constants/paths";
import { UserType } from "lib/constants/user-type";
import { useUserType } from "lib/hooks/use-user-type.hook";
import { LanguageDropdown } from "./components/language-dropdown/language-dropdown.component";
import { Tag } from "components/atoms/text/text.interface";
import { colors } from "lib/palette";
import {
    createSignInOnboardingTargetProps,
    SignInOnboardingTargets,
} from "components/providers/onboarding/sign-in";
import {
    HeaderProps,
    MOBILE_LOGO_ICON_NAME_BY_USER_TYPE,
    testIds,
} from "./header.interface";
import {
    StyledDesktopButtonBlock,
    StyledHeader,
    StyledHeaderButton,
    StyledHeaderLeftBlock,
    StyledHeaderMobileLogo,
    StyledHeaderProfileButtonDesktopContent,
    StyledHeaderRightBlock,
} from "./header.styled";

export const Header = ({
    isActive,
    isMobile,
    setIsActive,
    onBack,
    pageTitle,
    showBackButton,
}: HeaderProps) => {
    const { t } = useTranslation("common");

    const router = useRouter();
    const { logout } = useLogout();
    const userType = useUserType();

    const onLogoClick = () => {
        if (userType === UserType.B2B_USER) {
            void router.push(paths.STARTER_B2B);
        }
    };

    const redirectToProfileHandler = () => {
        // TODO: will add redirect to profile functionality
    };

    return (
        <StyledHeader
            isMobile={isMobile}
            isActive={isActive}
            setIsActive={setIsActive}
            className="site-header"
        >
            <StyledHeaderLeftBlock isMobile={isMobile}>
                {isMobile ? (
                    <>
                        <BurgerMenuButton
                            isActive={isActive}
                            setIsActive={setIsActive}
                        />
                        {userType && (
                            <StyledHeaderMobileLogo onClick={onLogoClick}>
                                <Icon
                                    name={
                                        MOBILE_LOGO_ICON_NAME_BY_USER_TYPE[
                                            userType
                                        ]
                                    }
                                />
                            </StyledHeaderMobileLogo>
                        )}
                    </>
                ) : (
                    <>
                        {showBackButton && (
                            <Button
                                variant="outlined-grey"
                                size={ButtonSize.EXTRA_SMALL}
                                onClick={onBack}
                                width={74}
                                showTitle
                                data-testid={testIds.desktopBackButton}
                            >
                                {t("defaultLayout.button.back", "Back")}
                            </Button>
                        )}
                        {pageTitle && (
                            <Text
                                tag={Tag.H1}
                                truncate
                                showTitle
                                size={18}
                                weight={700}
                                color={colors.black_1}
                            >
                                {pageTitle}
                            </Text>
                        )}
                    </>
                )}
            </StyledHeaderLeftBlock>
            <StyledHeaderRightBlock>
                <StyledDesktopButtonBlock
                    {...createSignInOnboardingTargetProps(
                        SignInOnboardingTargets.HELP_ICON,
                    )}
                >
                    <PageInfo />
                </StyledDesktopButtonBlock>
                <Menu
                    offset={4}
                    menuButton={
                        <StyledDesktopButtonBlock>
                            <StyledHeaderButton
                                {...createSignInOnboardingTargetProps(
                                    SignInOnboardingTargets.PROFILE_ICON,
                                )}
                            >
                                <Icon name={Icons.profileSvg} />
                            </StyledHeaderButton>
                            {!isMobile && (
                                <StyledHeaderProfileButtonDesktopContent
                                    data-testid={testIds.profileMenuButton}
                                >
                                    {t(
                                        "defaultLayout.button.profile",
                                        "Profile",
                                    )}
                                    <Icon name={Icons.downArrowSvg} />
                                </StyledHeaderProfileButtonDesktopContent>
                            )}
                        </StyledDesktopButtonBlock>
                    }
                    menuItems={[
                        {
                            children: (
                                <div data-testid={testIds.myProfileButton}>
                                    {t(
                                        "defaultLayout.button.myProfile",
                                        "My profile",
                                    )}
                                </div>
                            ),
                            onClick: redirectToProfileHandler,
                        },
                        {
                            children: (
                                <div data-testid={testIds.logOutButton}>
                                    {t(
                                        "defaultLayout.button.logOut",
                                        "Log out",
                                    )}
                                </div>
                            ),
                            onClick: logout,
                        },
                    ]}
                />
                <LanguageDropdown />
            </StyledHeaderRightBlock>
        </StyledHeader>
    );
};
